<template>
  <q-layout view="hHh lpr fff">
    <q-drawer dark v-model="toggleMobile" side="right">
      <q-tabs inline-label dense shrink align="right" class="mobile-menu" vertical>
        <q-tab dense flat icon="mdi-close" @click="toggleMobile = !toggleMobile" />
        <q-route-tab href="/#focuses" exact replace no-caps :ripple="false" class="focuses">
          <q-item><q-item-label>Areas of Focus</q-item-label></q-item>
        </q-route-tab>
        <q-route-tab href="/#insights" exact replace no-caps :ripple="false" class="insights">
          <q-item><q-item-label>Insights</q-item-label></q-item>
        </q-route-tab>
        <q-route-tab href="/#about" exact replace no-caps :ripple="false" class="about">
          <q-item><q-item-label>About</q-item-label></q-item>
        </q-route-tab>
        <q-route-tab no-caps href="/#contact" exact replace :ripple="false" class="contact">
          <q-item><q-item-label>Schedule A Call</q-item-label></q-item>
        </q-route-tab>
      </q-tabs>
    </q-drawer>

    <q-header class="bg-transparent text-white">
      <div class="header-wrapper">
        <q-toolbar>
          <q-toolbar-title shrink class="flex-left">
            <q-btn dense flat href="/#home" class="branding home" no-caps :ripple="false">
              <span class="large">137</span><span class="small"><span class="text-primary">&</span>Co.</span>
            </q-btn>
          </q-toolbar-title>
          <q-tabs inline-label dense shrink align="right" class="flex-right nav" >
            <q-route-tab href="/#focuses" exact replace no-caps :ripple="false" class="focuses">
              <q-item><q-item-label>Areas of Focus</q-item-label></q-item>
            </q-route-tab>
            <q-route-tab href="/#about" exact replace no-caps :ripple="false" class="about">
              <q-item><q-item-label>About</q-item-label></q-item>
            </q-route-tab>
            <q-route-tab href="/#insights" exact replace no-caps :ripple="false" class="insights">
              <q-item><q-item-label>Insights</q-item-label></q-item>
            </q-route-tab>
            <q-route-tab no-caps href="/#contact" exact replace :ripple="false" class="contact get-in-touch">
              <span>Schedule A Call</span>
            </q-route-tab>
          </q-tabs>
          <q-btn icon="mdi-menu" flat class="mobile-toggle" ripple="false" @click="toggleMobile = !toggleMobile" />
        </q-toolbar>
      </div>
    </q-header>

    <q-page-container>
      <router-view />
    </q-page-container>

    <div class="footer">
      <div class="footer-wrapper">
        <div class="top">
          <q-list dense class="sitemap">
            <q-item style="padding-left: unset"><a href="/">Home</a></q-item>
            <q-item><a href="/#focuses">Areas of Focus</a></q-item>
            <q-item><a href="/#about">About</a></q-item>
            <q-item><a href="/#insights">Insights</a></q-item>
            <q-item><a href="/news">News</a></q-item>
            <q-item><a href="/#contact">Schedule A Call</a></q-item>
          </q-list>
          <q-space />
          <q-list class="socials-ai">
            <q-item v-for="(social, index) in contactOptions" :key="index">
              <q-btn dense unelevated :icon="social.icon" :href="social.handle" target="_blank">
                <q-tooltip anchor="top right">{{ social.platform }}</q-tooltip>
              </q-btn>
            </q-item>
          </q-list>
        </div>
        <p>© Copyright 2025 - 137 & Co.  All rights reserved.</p>
      </div>
    </div>

  </q-layout>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useDataStore } from 'src/stores/data-store';
import { version } from '../../package.json';

export default defineComponent({
  setup() {
    const ticking = ref(false);
    const route = useRoute();
    const dataStore = reactive(useDataStore());
    const contactOptions = ref<Array<{ platform: string, icon: string, handle: string }>>()
    const seoData = ref<{
      og?: {
        title?: string,
        description?: string,
        type?: string,
        url?: string
      },
      twitter?: {
        card?: string,
        title?: string,
        description?: string,
        site?: string,
        creator?: string
      },
      title?: string,
      description?: string,
      keywords?: string
    }>({
      title: "Your Strategic Partner in Technology | 137 & Co.",
      description: "Discover Cumberland's Strategic  Partner in Technology at 137 & Co., offering cutting-edge solutions in emerging technologies, process automation, and web development.  From blockchain to artificial intelligence, our seasoned team will provide tailored services for businesses seeking innnovation.",
      keywords: "Blockchain, Artificial Intelligence, Web Development, Digital Transformation, Emerging Technologies, Cumberland, MD, Business Consulting, Entrepreneurship Support, Decentralized Networks, AI Supply Chain Management, Education and Training",
      twitter: {
        card: 'summary'
      },
      og: {

      }
    });
    const toggleMobile = ref(false);

    const setSeoData = () => {
      let i: number, seo: any;

      i = dataStore.$state.sections.findIndex(s => s.name.toLowerCase() === route.hash.toLowerCase().replace('#',''));
      if(i == -1) i = 0;

      // Update to work with the various sub-sections of the site
      // IMPORTANT!!
      seo = dataStore.$state.sections[i].meta;

      if(!seo) return;

      if(seo.title) seoData.value.title = seo.title;
      if(seo.description) seoData.value.description = seo.description;
      if(seo.keywords && seo.keywords.length > 0) seoData.value.keywords = seo.keywords.join(", ");
      if(seo.og?.title) seoData.value.og!.title = seo.og.title; else seoData.value.og!.title = seoData.value.title;
      if(seo.og?.description) seoData.value.og!.description = seo.og.description; else seoData.value.og!.description = seoData.value.description;
      if(seo.og?.type) seoData.value.og!.type = seo.og.type; else seoData.value.og!.type = 'website';
      if(seo.og?.url) seoData.value.og!.url = seo.og.url; else seoData.value.og!.url = 'https://137andco.com/#' + route.hash;
      if(seo.twitter?.card) seoData.value.twitter!.card = seo.twitter.card; else seoData.value.twitter!.card = 'summary';
      if(seo.twitter?.title) seoData.value.twitter!.title = seo.twitter.title; else seoData.value.twitter!.title = seoData.value.title;
      if(seo.twitter?.description) seoData.value.twitter!.description = seo.twitter.description; else seoData.value.twitter!.description = seoData.value.description;
      if(seo.twitter?.site) seoData.value.twitter!.site = seo.twitter.site; else seoData.value.twitter!.site = '137andco';
      if(seo.twitter?.creator) seoData.value.twitter!.creator = seo.twitter.creator; else seoData.value.twitter!.creator = 'AProgrammersArt';

      document.title = seoData.value.title!;

      document.querySelector('meta[name="description"]')?.setAttribute("content", seoData.value.description!);
      document.querySelector('meta[name="keywords"]')?.setAttribute("content", seoData.value.keywords!);
      document.querySelector('meta[name="og:title"]')?.setAttribute("content", seoData.value.og!.title!);
      document.querySelector('meta[name="og:description"]')?.setAttribute("content", seoData.value.og!.description!);
      document.querySelector('meta[name="og:type"]')?.setAttribute("content", seoData.value.og!.type!);
      document.querySelector('meta[name="og:url"]')?.setAttribute("content", seoData.value.og!.url!);
      document.querySelector('meta[name="twitter:card"]')?.setAttribute("content", seoData.value.twitter!.card!);
      document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", seoData.value.twitter!.title!);
      document.querySelector('meta[name="description"]')?.setAttribute("content", seoData.value.twitter!.description!);
      document.querySelector('meta[name="site"]')?.setAttribute("content", seoData.value.twitter!.site!);
      document.querySelector('meta[name="creator"]')?.setAttribute("content", seoData.value.twitter!.creator!);
    }

    watch(route, (n,o) => {
      // console.log(n.hash);
      setSeoData();
    },{ flush: 'pre', immediate: true, deep: true })

    addEventListener('scroll', (e) => {
      if(!ticking.value) {
        window.requestAnimationFrame(() => {
          if(window.scrollY > 200) {
            document.querySelector('.q-header')?.classList.add('darken');
            // document.querySelector('.sidebar')?.classList.add('fixme');
          }
          else {
            document.querySelector('.q-header')?.classList.remove('darken');
            // document.querySelector('.sidebar')?.classList.remove('fixme');
          }
        })
      }
    });

    onBeforeMount(() => {
      console.log('Version: ', version);

      dataStore.$state.sections.forEach(section => {
        document.body.style.setProperty(`--${section.name.toLowerCase()}Background`, `url('${section.backgroundImage}')`);
        if(section.name === 'Contact') contactOptions.value = section.socialMedia
      })

      setSeoData();
    })

    return {
      toggleMobile,
      contactOptions,
      setSeoData,
      version,
      route
    }
  }
})
</script>


<style scoped lang="scss">
  .q-footer {
    font-size: 0.75rem;
    background-color: rgba(0,0,0,0.9);

    .footer-wrapper {
      color: rgba(255,255,255,0.8);
      padding: 0;
    }
  }

  // .darken .branding {
  //   font-size: 2.5rem !important;
  //   line-height: 4rem !important;
  // }
</style>

<style lang="css">
  /* Image Preloader */
  body::after {
    position: absolute; width: 0; height: 0; overflow: hidden; z-index: -1;
    content: url('/assets/about_back_layer.webp') url('/assets/contact-back.webp') url('/assets/focuses_background.webp');
  }
</style>

