<template>
  <q-page class="column career-layout">
    <article>
      <div class="back-to-home"><q-btn to="/" dense outline size="sm" padding="sm md" label="Back to home" color="grey-6" /></div>
      <header :style="`background-image: url('${career.titleImage}'); background-position: center center; background-size: cover;`" v-show="isCurrentJobShowing">

      </header>

      <div class="title-wrapper">
          <h1 class="title">{{ career.title }}</h1>
      </div>

      <section>
        <h2 v-show="!isCurrentJobShowing">Career Posts:</h2>
        <q-list v-show="!isCurrentJobShowing">
          <q-item class="item" v-for="(item, index) in entries" :key="index" clickable>
            <q-item-section>
              <q-item-label lines="1">{{ item.title }}</q-item-label>
              <q-item-label caption>{{ item.date }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <div class="content" v-show="!isCurrentJobShowing" v-html="career.content"></div>
        <div class="career-detail" v-show="isCurrentJobShowing">
          <p class="about-137">137 & Co. is a technology consulting company based in Cumberland, Maryland. We help businesses navigate the complexities of modern technology and leverage innovative solutions to drive success. As we continue to grow and expand our services, we're seeking an enthusiastic Technology Apprentice to join our team!</p>
          <div v-html="careerDetail"></div>
        </div>
      </section>
      <!-- <div class="date-author"></div> -->
      <a href="mailto:hello@137andco.com?subject=Technology%20Apprentice" title="Submit Resume" class="submit-resume"><span>Submit Resume</span></a>
    </article>
  </q-page>
</template>

<script>
import { useDataStore } from 'src/stores/data-store';
import { useRoute } from 'vue-router';
import { reactive, ref } from 'vue';

export default {
  name: 'CareersPage',
  components: {
    // Import and register components here
  },
  setup() {
    const route = useRoute()
    const data = reactive(useDataStore())
    const s = data.$state.sections.find(d => d.name === "Careers")
    const currentJob = s.careers.find(car => car.slug === route.params.id)
    // const isCurrentJobShowing = ref(currentJob.value ? true : false)

    const entries = ref(s.careers)

    return {
      entries,
      // isCurrentJobShowing,
      currentJob,
      career: currentJob
    }
  },
  computed: {
    careerDetail () {
      const job = this.currentJob
      let html = ''
      html += '<h3>Overview:</h3><p>' + job.overview + '</p>'
      html += '<h3>Reports to:</h3><p>' + job.reports + '</p>'
      html += '<h3>Responsibilities:</h3><ul>'
      for (const responsibility of job.responsibilities) {
        html += '<li>' + responsibility + '</li>'
      }
      html += '</ul><h3>Requirements:</h3><ul>'
      for (const qualification of job.qualifications) {
        html += '<li>' + qualification + '</li>'
      }
      html += '</ul><h3>Preferred Qualifications</h3><ul>'
      for (const prefer of job.preferred) {
        html += '<li>' + prefer + '</li>'
      }
      html += '</ul><h3>Benefits:</h3><ul>'
      for (const benefit of job.benefits) {
        html += '<li>' + benefit + '</li>'
      }
      html += '</ul><p><b>Where to Apply:</b></p><p>' + job.whereToApply + '</p>'
      return html
    },
    isCurrentJobShowing () {
      if(this.currentJob) return true;
      else return false;
    }
  },
}
</script>

<style scoped lang="scss">
  .q-page {
    background-color: rgba(255,255,255,0.04);
    background-image: url('/assets/careers/careers-background.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }

  .item {
    cursor: pointer;
  }
</style>
