<template>
  <q-page class="column">
    <div class="parallax-container">
      <div class="home">
        <div class="parallax home-bg" data-speed="0.04"></div>
        <div class="parallax home-bg-mid" data-speed="0.2"></div>
        <canvas class="parallax canvas" data-speed="0.6"></canvas>
        <div class="parallax home-bg-front" data-speed="0.9"></div>
      </div>
      <div class="focuses">
        <div class="parallax focuses-bg-buffer" data-speed="0.9"></div>
        <div class="parallax focuses-bg" data-speed="0.9"></div>
      </div>
      <div class="about">
        <div class="parallax about-bg" data-speed="0.9"></div>
        <div class="parallax about-bg-buffer" data-speed="0.9"></div>
        <div class="parallax contact-bg" data-speed="0.001"></div>
      </div>
    </div>

    <div class="section home" id="home">
      <header class="anim">
        <div class="head-top anim">
          <h1>Your Strategic Partner<br/> in Technology.</h1>
          <q-btn  dense flat class="branding" no-caps :ripple="false" to="/#home">
              <div class="brand-mini">
                <span class="large">137</span>
                <span class="small"><span class="text-primary">&</span>Co.</span>
              </div>
          </q-btn>
        </div>
        <div class="head-bottom anim">
          <q-btn class="schedule" color="primary" fab-mini padding="sm lg" :ripple="false" to="/#contact">
            Take the First Step
          </q-btn>
        </div>
      </header>
      <div class="scroll-down anim">Scroll down to learn more.</div>
    </div>
    <div class="section focuses" id="focuses">
      <div class="">
        <h2 v-html="focusesData?.title" class="anim"></h2>
        <h3 class="focusesTitle anim" v-html="focusesData?.focusesTitle"></h3>
        <ul class="focuses-list">
          <li v-for="(c, i) in focusesData?.focuses" :key="i" class="anim">
            <q-icon :name="c.icon" />
            <div class="focus-name">{{ c.name }}</div>
            <div class="focus-description">{{ c.description }}</div>
          </li>
        </ul>
        <div class="testimonial anim">
          &ldquo;Richard's ability to understand complex subjects and translate them into elegant solutions is outstanding.  His attention to detail and commitment to quality have set a high standard for our team.  I was very confident in his communications with clients and the internal team.&rdquo; <br/><cite><img src="/assets/testimonials/andrii.jpg" width="28" /> Andrii Kubai - Sweetrush, Inc.</cite>
        </div>
      </div>
    </div>
    <div class="section about" id="about">
      <div class="full">
        <h2 v-html="aboutData?.title" class="anim"></h2>
        <div class="content-wrapper">
          <div v-for="(sub, i) in aboutData?.subSections" :key="i" :class="!aboutData!.subSections![i+1] ? 'content last anim' : 'content anim'">
            <h4 v-html="sub.name" />
            <div v-html="sub.description" />
          </div>
        </div>
      </div>
    </div>
    <div class="section insights text-white" id="insights">
      <div class="full">
        <h2 v-html="insightsData?.title" class="anim"></h2>
        <q-list>
          <q-item v-for="(insight, i) in insightsData?.insights" :key="i" clickable :to="`/insights/${insight.slug}`" class="column anim">
            <q-img fit="cover" :src="insight.titleImage" class="anim" />
            <q-item-section v-html="insight.title" class="title anim" />
            <q-item-section style="align-content: center" class="anim"><q-btn unelevated fab-mini padding="sm lg" color="primary" label="Learn More" icon-right="chevron_right" style="max-width: max-content;" /></q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
    <div class="section contact" id="contact">
      <header class="left">
        <h2 v-html="contactData?.title" class="anim"></h2>
        <h5 v-html="contactData?.subTitle" class="anim"></h5>
        <p class="title anim">Give us a call</p>
        <p class="data anim phone"><a href="tel+12405221399">(240) 522-1399</a></p>
        <p class="title anim">Send us an email</p>
        <p class="data anim" v-html="contactData?.contactInfo?.email"></p>
        <p class="title anim">Visit Our Office</p>
        <p class="anim"><span>~Opening Soon~</span><br/><a v-html="contactData?.contactInfo?.address" href="https://www.google.com/maps/place/137+Virginia+Ave,+Cumberland,+MD+21502/@39.6361166,-78.7639901,535m/data=!3m1!1e3!4m6!3m5!1s0x89ca8f2694b4dd03:0x3c577e44bbb4af92!8m2!3d39.6365937!4d-78.7628872!16s%2Fg%2F11c25w_1ls?entry=ttu&g_ep=EgoyMDI0MDgyMS4wIKXMDSoASAFQAw%3D%3D" target="_blank"></a></p>
        <p class="anim">Proudly serving the local Western Maryland, West Virginia, and Pennsylvania regions as well as our friends and clients across the United States and Internationally.</p>
        <p class="other anim">No solicitations or spam please.<br/>We reserve the right to cancel any meeting requests.</p>
        <!-- <p class="copyright anim"><small>© Copyright 2025 - 137 & Co.  All rights reserved.</small></p> -->
      </header>
      <div class="right anim">
        <iframe src="https://zcal.co/i/nRkMZAoF?embed=1&embedType=iframe" loading="lazy" style="border:none; min-width: 320px; min-height: 544px; height: 1065px; width: 600px" id="zcal-invite"></iframe>
      </div>
    </div>
  </q-page>
</template>

<script lang="ts">
import { API_HEADERS, API_ROUTE, handleResponse } from 'src/stores/utils';
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { useDataStore } from 'src/stores/data-store';
import * as THREE from 'three';

export default defineComponent({
  setup() {
    const submittingForm = ref(false);
    const showConfirmation = ref(false);
    const data = reactive(useDataStore());
    const formData = ref<{
      name: string,
      email: string,
      phone: string,
      message: string,
      hidden?: string | undefined
    }>({
      name: '',
      email: '',
      phone: '',
      message: ''
    })
    const hiddenValue = ref('Rose');

    onMounted(() => {
      const q = (c: string) => { return document.querySelector(`.${c}`); }

      const canvas = q('canvas');
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(65, window.innerWidth / (window.innerHeight * 1.2), 0.01, 100);
      const renderer = new THREE.WebGLRenderer({
        canvas: canvas!,
        alpha: true,
        antialias: true
      });
      renderer.setSize(window.innerWidth, window.innerHeight * 1.2);
      renderer.setClearColor(0x000000,0);

      const cloudTextures: any[] = [];
      for(let i = 0; i < 7; i++) {
        let c = new THREE.TextureLoader();
        cloudTextures[i] = c.load(`/assets/clouds/cloud${i}.png`);
      }

      const geometry = new THREE.PlaneGeometry(100,100);
      const material = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0 });
      const plane = new THREE.Mesh(geometry, material);
      scene.add(plane);

      function generateClouds(x: number, z: number) {
        const i = Math.floor(Math.random() * 5);
        const cGeo = new THREE.PlaneGeometry(18,10);
        const cMat = new THREE.MeshBasicMaterial({ map: cloudTextures[i], transparent: true, opacity: 0.00 });
        const cloud = new THREE.Mesh(cGeo, cMat);
        cloud.position.set(x, 0, z);
        return cloud;
      }

      let clouds: any[] = [];

      for(let i = 0; i < 75; i++) {
        const x = Math.floor(Math.random() * 200) - 100;
        const z = Math.floor(Math.random() * 75) - 50;
        clouds.push(generateClouds(x,z));
        scene.add(clouds[i]);
      }

      camera.lookAt(plane.position);
      camera.position.z = 40;
      camera.position.y = 3;

      function animate() {
        for(let i = 0; i < clouds.length; i++) {
          clouds[i].position.z += 0.01;
          if(clouds[i].material.opacity < 0.90) clouds[i].material.opacity += 0.002;
          if(clouds[i].position.z > 24) clouds[i].material.opacity -= 0.003;

          if(clouds[i].position.z > 34) {
            clouds[i].material.opacity = 0;
            clouds[i].position.z = Math.floor(Math.random() * 50) - 50;
            clouds[i].position.x = Math.floor(Math.random() * 200) - 100;
          }
        }
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      }

      animate();

      window.addEventListener('resize', () => {
        renderer.setSize(window.innerWidth, window.innerHeight, false);
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
      });

      const m = {
        home: q('section.home'),
        focuses: q('section.focuses'),
        about: q('section.about'),
        insights: q('section.insights'),
        contact: q('section.contact')
      }

      const n = {
        home: q('branding.home'),
        focuses: q('nav .focuses'),
        about: q('nav .about'),
        insights: q('nav .insights'),
        contact: q('nav .contact')
      }

      const o = new IntersectionObserver((e) => {
        e.forEach((sec) => {
          if(sec.isIntersecting) {
            const i = sec.target.id as keyof typeof m;
            document.querySelector('.branding')?.classList.remove('q-tab--active');
            document.querySelectorAll('.q-tab')?.forEach(el => el.classList.remove('q-tab--active'));
            n[i]?.classList.add('q-tab--active');
          }
        })
      },{
        root: null,
        rootMargin: '1px',
        threshold: 0.7
      });

      Object.keys(m).forEach(v => {
        o.observe(m[v as keyof typeof m] as HTMLElement);
      });

      const startAnim = (entries: any[], _observer: any) => {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            if(!entry.target.classList.contains("slide-in-down")) entry.target.classList.add("slide-in-down");
          }
        });
      }
      const animObserver = new IntersectionObserver(startAnim, { root: null, rootMargin: '0px', threshold: 0.6 });

      const animTargets = document.querySelectorAll('.anim');
      animTargets.forEach(t => {
        animObserver.observe(t);
      });

      const layers = document.querySelectorAll('.parallax');

      // Parallax
      document.addEventListener('scroll', () => {
        layers.forEach(layer => {
          const p = window.scrollY * Number(layer.getAttribute('data-speed'));

          // if(layer.classList.contains('home-bg')) (layer as HTMLElement).style.transform = `translateY(${p}px)`;
          // else
          (layer as HTMLElement).style.transform = `translateY(-${p}px)`;
        });
      });

      layers.forEach(layer => {
        (layer as HTMLElement).style.transform = `translateY(${window.scrollY * Number(layer.getAttribute('data-speed'))}px)`;
      });
    });

    return {
      formData,
      hiddenValue,
      submittingForm,
      showConfirmation,
      focusesData: data.$state.sections.find(s => s.name.toLowerCase() === 'focuses'),
      insightsData: data.$state.sections.find(s => s.name.toLowerCase() === 'insights'),
      aboutData: data.$state.sections.find(s => s.name.toLowerCase() === 'about'),
      // careersData: data.$state.sections.find(s => s.name.toLowerCase() === 'careers'),
      contactData: data.$state.sections.find(s => s.name.toLowerCase() === 'contact')
    }
  },
  methods: {
    checkValidation(name: string, req: boolean | undefined = false, min: number | undefined = 0) {
      if(!req) return true;
    },
    async sendMail() {
      if(this.formData.name.length < 5 ||
        this.formData.email.length < 5 ||
        this.formData.phone.length < 10 ||
        this.formData.message.length < 30 ||
        this.hiddenValue !== 'Rose'
      ) return;

      this.formData['hidden'] = this.hiddenValue;

      this.submittingForm = true;
      const e = await handleResponse(await fetch(`${API_ROUTE}/emails`, { method: 'POST', headers: API_HEADERS, body: JSON.stringify(this.formData) }))

      if(e) {
        this.clearForm();
        this.submittingForm = false;
        this.showConfirmation = true;
      }
    },
    clearForm() {
      this.formData.name = ''
      this.formData.email = ''
      this.formData.phone = ''
      this.formData.message = ''
    }
  }
})
</script>

<style lang="scss" scoped>
  .anim {
    opacity: 0;
  }

  .slide-in-down {
    animation: 0.5s ease-out 0.2s 1 slideInDown forwards;
  }

  @keyframes slideInDown {
    0% {
      transform: translateY(30%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1.0;
    }
  }

  .branding {
      position: relative;
      padding: 0;
      font-size: 4rem;
      line-height: 1;
      min-height: unset;
      font-family: 'Literata';
      color: white;

      .brand-mini {
        border-radius: 0.25rem;
        background-color: transparent;
        border: 2px solid rgba(255,255,255,0.8);
        border-radius: 0.5rem;
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .small {
          font-size: 2rem !important;
        }
      }
    }
</style>

<style lang="scss">
  #home .q-focus-helper { display: none !important; }
</style>
