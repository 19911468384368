<template>
  <q-page class="column insight-layout">
    <div class="insights-wrapper" v-if="!single">
      <q-list>
          <q-item v-for="(insight, i) in insights" :key="i" clickable :href="`/insights/${insight.slug}`" exact replace class="column anim">
            <q-img fit="cover" :src="insight.titleImage" class="anim" />
            <q-item-section v-html="insight.title" class="title anim" />
            <q-item-section style="align-content: center" class="anim"><q-btn unelevated fab-mini padding="sm lg" color="primary" label="Learn More" icon-right="chevron_right" style="max-width: max-content;" /></q-item-section>
          </q-item>
        </q-list>
    </div>
    <article v-if="single">
      <!-- <div class="back-to-home"><q-btn to="/" no-caps dense unelevated size="sm" icon="arrow_left" label="Back to Home" color="transparent" text-color="white" /></div> -->
      <header :style="`background-image: url('${insight.titleImage}'); background-position: center center; background-size: cover;`">

      </header>

      <div class="title-wrapper">
          <h1 class="title">{{ insight.title }}</h1>
      </div>

      <!-- <div class="date-author">{{  new Date(insight.date).toDateString() }}</div> -->
      <div class="content" v-html="insight.content"></div>
      <a title="Schedule A Call" class="contact-us" @click="goToContact"><span>Schedule A Call</span></a>
    </article>
  </q-page>
</template>

<script>
import { useDataStore } from 'src/stores/data-store';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue';

export default {
  name: 'InsightsPage',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const data = reactive(useDataStore())
    const single = ref(false);

    const s = data.$state.sections.find(d => d.name === "Insights")
    const i = s.insights.find(ins => ins.slug === route.params.id)
    const j = s.insights

    if(i && route.params.id) single.value = true;

    if(!single.value) router.replace({ path: '/insights' })

    const goToContact = async() => {
      router.push({ path: '/', hash: '#contact' })
    }

    return {
      insight: i,
      insights: j,
      single,
      goToContact
    }
  }
}
</script>

<style scoped lang="scss">
  .q-page {
    background-color: rgba(255,255,255,0.04);
    background-image: url('/assets/insights/energy-background.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }

  .contact-us {
    cursor: pointer;
  }
</style>
