<template>
  <q-page class="column news-layout">
    <div class="news-wrapper" v-if="!single">
      <q-list>
          <q-item v-for="(article, i) in articles" :key="i" clickable :href="`/news/${article.slug}`" exact replace class="column anim">
            <q-img fit="cover" :src="article.titleImage" class="anim" />
            <q-item-section v-html="article.title" class="title anim" />
            <q-item-section style="align-content: center" class="anim"><q-btn unelevated fab-mini padding="sm lg" color="primary" label="Learn More" icon-right="chevron_right" style="max-width: max-content;" /></q-item-section>
          </q-item>
        </q-list>
    </div>
    <article v-if="single">
      <!-- <div class="back-to-home"><q-btn to="/" no-caps dense unelevated size="sm" icon="arrow_left" label="Back to Home" color="transparent" text-color="white" /></div> -->
      <header :style="`background-image: url('${article.titleImage}'); background-position: center center; background-size: cover;`">

      </header>

      <div class="title-wrapper">
          <h1 class="title">{{ article.title }}</h1>
      </div>

      <div class="date-author">Written by:  <a href="https://137andco.com" title="Richard Ammerman, Founder & CTO | 137 & Co.">Richard Ammerman</a> -on- {{  new Date(article.date).toDateString() }}</div>
      <div class="content" v-html="article.content"></div>
      <a title="Schedule A Call" class="contact-us" @click="goToContact"><span>Schedule A Call</span></a>
    </article>
  </q-page>
</template>

<script>
import { useDataStore } from 'src/stores/data-store';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref } from 'vue';

export default {
  name: 'NewsPage',
  setup() {
    const route = useRoute()
    const router = useRouter()
    const data = reactive(useDataStore())
    const single = ref(false);

    const s = data.$state.sections.find(d => d.name === "News")
    const i = s.articles.find(ins => ins.slug === route.params.id)
    const j = s.articles

    if(i && route.params.id) single.value = true;

    if(!single.value) router.replace({ path: '/news' })

    const goToContact = async() => {
      router.push({ path: '/', hash: '#contact' })
    }

    return {
      article: i,
      articles: j,
      single,
      goToContact
    }
  }
}
</script>

<style scoped lang="scss">
  .q-page {
    background-color: rgba(255,255,255,0.04);
    background-image: url('/assets/news/news_background.webp');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0, transparent 0%, #1d1d1d 100%);
      z-index: 0;
      height: 200px;
      width: 100%;
    }
  }

  .contact-us {
    cursor: pointer;
  }
</style>
