import { Buffer } from 'buffer/index';

const authKey = process.env.AUTH_TOKEN ?? "undefined";
const bufferData = Buffer.from(authKey).toString('base64');

const API_HEADERS = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + bufferData
}

const POST_HEADERS = {
  'Authorization': 'Bearer ' + bufferData
}

const API_ROUTE = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' :
                  process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production' ? '/api/' :
                  'undefined';

const handleResponse = async (response:Response) => {
  const isJson = response.headers?.get('content-type')?.includes('application/json');
  const data = isJson ? await response.json() : null;

  if (!response.ok) {
    const error = (data && data.error) || response.status;
    return Promise.reject(error);
  }

  return Promise.resolve(data);
}

export {
  API_HEADERS,
  POST_HEADERS,
  API_ROUTE,
  handleResponse
}
